






























































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import { SelectListItem } from "@/api/appService";

@Component({
  name: "MemberPaymentOrderList",
  components: {
    PagedTableView,
  },
})
export default class BrandList extends Vue {
  queryForm = {
    customerCode: "",
    isPaid: undefined,
  };

  isPaidList = [
    {
      text: (this as any).$l.getLanguageText("memberPaymentOrder.paid"),
      value: true,
    },
    {
      text: (this as any).$l.getLanguageText("memberPaymentOrder.unPaid"),
      value: false,
    },
  ];

  orderPayMethodList = [] as SelectListItem[];

  created() {
    this.fetchOrderPayMethodList();
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.financeMemberPaymentOrder.getAll(params);
  }

  async fetchOrderPayMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderPayMethod" })
      .then((res) => {
        this.orderPayMethodList = res;
      });
  }

  getOrderPayMethodText(value: any) {
    let text = "";
    for (let i in this.orderPayMethodList) {
      if (this.orderPayMethodList[i].value === value) {
        text = this.orderPayMethodList[i].text!;
      }
    }
    return text;
  }
}
